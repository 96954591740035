var cards_operador = [];
var cards_recurso = [];
var cards_ordem_de_producao = [];
var cards_apontamento_aberto = [];
var cards_motivo_de_parada;
var view = "";
var selected_operador = null;
var selected_recurso = null;
var selected_ordem_de_producao = null;
var selected_motivo_de_parada = null;
var cur_interval_id = null;

frappe.ready(async function () {
  if (window.location.href.includes("/apontamento-app")) {
    $("nav.navbar.navbar-light.navbar-expand-lg").remove();
    const senha = localStorage.getItem("apontamento-app-pass");
    if (!senha) {
      $("#apontamento-app").hide();
      $("#pass-modal").show();
      $("#login-btn").on("click", async () => {
        const pass_input = $("#pass").val();
        if (!pass_input)
          return frappe.throw("Informe a senha para realizar a verificação.");
        const { message } = await frappe.call(
          "nxlite.nxlite.web_template.apontamento_app.apontamento_app.verify_pass_site_config",
          { password: pass_input }
        );
        if (await message) {
          localStorage.setItem("apontamento-app-pass", pass_input);
          window.location.reload();
        } else {
          frappe.throw("Credencial incorreta.");
        }
      });
    }
    if (senha) {
      await frappe
        .call(
          "nxlite.nxlite.web_template.apontamento_app.apontamento_app.verify_pass_site_config",
          { password: senha }
        )
        .then(async (verify_res) => {
          if (verify_res.message == 1) {
            $("#pass-modal").hide();
            $("#apontamento-app").show();
            $("footer.web-footer").remove();
            $("#tab_apontamento_aberto").on("click", toggle_tab_on_click);
            $("#tab_motivo_de_parada").on("click", toggle_tab_on_click);
            $("#tab_ordem_de_producao").on("click", toggle_tab_on_click);
            $("#tab_recurso").on("click", toggle_tab_on_click);
            $("#tab_operador").on("click", toggle_tab_on_click);
            $("#pesquisa").on("click", handle_input_geral);
            document.getElementById("abrir_parada").onclick = function () {
              document.getElementById("motOptions").style.display = "block";
              document.getElementById("fecOptions").style.display = "none";
            };

            await get_initial_data().then(() => {
              $(`${view}`).trigger("click");
            });
            frappe.realtime.on("apontamento_app", (data) => {
              apontamento_app_realtime(data);
            });
          } else {
            //LOGIN SITUATION
            $("#apontamento-app").hide();
            $("#pass-modal").show();
            $("#login-btn").on("click", async () => {
              const pass_input = $("#pass").val();
              if (!pass_input)
                return frappe.throw(
                  "Informe a senha para realizar a verificação."
                );
              const { message } = await frappe.call(
                "nxlite.nxlite.web_template.apontamento_app.apontamento_app.verify_pass_site_config",
                { password: pass_input }
              );
              if (await message) {
                localStorage.setItem("apontamento-app-pass", pass_input);
                window.location.reload();
              } else {
                frappe.throw("Credencial incorreta.");
              }
            });
          }
        });
    }
  }
});

function extractValues(data) {
  let result = [];
  function recursiveExtract(input) {
    if (Array.isArray(input)) {
      input.forEach((element) => recursiveExtract(element));
    } else if (input !== null && typeof input === "object") {
      Object.values(input).forEach((value) => recursiveExtract(value));
    } else {
      result.push(input);
    }
  }
  recursiveExtract(data);
  return result;
}

const handle_input_geral = (e) => {
  const pesquisa_input = $($("#pesquisa_geral")[0]).val();
  const pesquisa_data = $($("#input_data")[0]).val();
  switch (view) {
    case "cards_apontamento_aberto": {
      const filtered = cards_apontamento_aberto.filter((apt_aberto) => {
        let found_value = false;
        let match_data = false;
        if (pesquisa_input) {
          if (pesquisa_input.length > 0) {
            const values = extractValues(apt_aberto);
            const found = values.find((v) =>
              typeof v == "string"
                ? v.includes(pesquisa_input)
                : typeof v == "number"
                ? `${v}`.includes(pesquisa_input)
                : null
            );
            if (found) found_value = true;
          } else found_value = true;
        } else found_value = true;
        if (pesquisa_data) {
          if (pesquisa_data.length > 0) {
            if (
              moment(apt_aberto.apontamento.dt_inicio)._a[0] ==
                moment(pesquisa_data)._a[0] &&
              moment(apt_aberto.apontamento.dt_inicio)._a[1] ==
                moment(pesquisa_data)._a[1] &&
              moment(apt_aberto.apontamento.dt_inicio)._a[2] ==
                moment(pesquisa_data)._a[2]
            )
              match_data = true;
          } else match_data = true;
        } else match_data = true;
        return found_value && match_data;
      });
      render_card_template_with_this_context(
        filtered,
        card_template_apontamento_aberto,
        toggle_card,
        toggle_plus_apontamento_aberto,
        "apontamento"
      );
      break;
    }
    case "cards_motivo_de_parada": {
      const filtered = cards_motivo_de_parada
        .filter((apt_aberto) => {
          let found_value = false;
          let match_data = false;
          if (pesquisa_input) {
            if (pesquisa_input.length > 0) {
              const values = extractValues(apt_aberto);
              const found = values.find((v) =>
                typeof v == "string"
                  ? v.includes(pesquisa_input)
                  : typeof v == "number"
                  ? `${v}`.includes(pesquisa_input)
                  : null
              );
              if (found) found_value = true;
            } else found_value = true;
          } else found_value = true;
          if (pesquisa_data) {
            if (pesquisa_data.length > 0) {
              if (
                moment(apt_aberto.apontamento.dt_inicio)._a[0] ==
                  moment(pesquisa_data)._a[0] &&
                moment(apt_aberto.apontamento.dt_inicio)._a[1] ==
                  moment(pesquisa_data)._a[1] &&
                moment(apt_aberto.apontamento.dt_inicio)._a[2] ==
                  moment(pesquisa_data)._a[2]
              )
                match_data = true;
            } else match_data = true;
          } else match_data = true;
          return found_value && match_data;
        })
        .map((d) => ({
          ...d,
          ordem_de_producao: d.ordem_de_producao ? d.ordem_de_producao : null,
        }));
      render_card_template_with_this_context(
        filtered,
        card_template_motivo_de_parada,
        toggle_card,
        () => {},
        "apontamento"
      );
      break;
    }
    case "cards_ordem_de_producao": {
      const filtered = cards_ordem_de_producao
        .filter((op) => {
          let found_value = false;
          let match_data = false;
          if (pesquisa_input) {
            if (pesquisa_input.length > 0) {
              const values = extractValues(op);
              const found = values.find((v) =>
                typeof v == "string"
                  ? v.includes(pesquisa_input)
                  : typeof v == "number"
                  ? `${v}`.includes(pesquisa_input)
                  : null
              );
              if (found) found_value = true;
            } else found_value = true;
          } else found_value = true;
          if (pesquisa_data) {
            if (pesquisa_data.length > 0) {
              if (
                moment(op.dt_entrega)._a[0] == moment(pesquisa_data)._a[0] &&
                moment(op.dt_entrega)._a[1] == moment(pesquisa_data)._a[1] &&
                moment(op.dt_entrega)._a[2] == moment(pesquisa_data)._a[2]
              )
                match_data = true;
            } else match_data = true;
          } else match_data = true;
          return found_value && match_data;
        })
        .map((card) => {
          if (card.estagio == "Em produção") {
            const found_apt_aberto = card.apontamentos
              ? card.apontamentos.find(
                  (apt) => apt.apontamento.status == "Aberto"
                )
              : undefined;
            return found_apt_aberto
              ? {
                  apontamento: found_apt_aberto.apontamento,
                  ordem_de_producao: card,
                  operador: found_apt_aberto.operador,
                  recurso: found_apt_aberto.recurso,
                }
              : {
                  apontamento: {},
                  ordem_de_producao: card,
                  operador: {},
                  recurso: {},
                };
          }
        });
      render_card_template_with_this_context(
        filtered,
        card_template_ordem_de_producao,
        toggle_card,
        toggle_plus_ordem_de_producao,
        "ordem_de_producao"
      );
      break;
    }
    case "cards_recurso": {
      const filtered = cards_recurso
        .filter((recurso) => {
          let found_value = false;
          let match_data = false;
          if (pesquisa_input) {
            if (pesquisa_input.length > 0) {
              const values = extractValues(recurso);
              const found = values.find((v) =>
                typeof v == "string"
                  ? v.includes(pesquisa_input)
                  : typeof v == "number"
                  ? `${v}`.includes(pesquisa_input)
                  : null
              );
              if (found) found_value = true;
            } else found_value = true;
          } else found_value = true;
          if (pesquisa_data) {
            if (pesquisa_data.length > 0) {
              if (recurso.apontamentos) {
                const has_dt = recurso.apontamentos.find(
                  (apt) =>
                    moment(apt.apontamento.dt_inicio)._a[0] ==
                      moment(pesquisa_data)._a[0] &&
                    moment(apt.apontamento.dt_inicio)._a[1] ==
                      moment(pesquisa_data)._a[1] &&
                    moment(apt.apontamento.dt_inicio)._a[2] ==
                      moment(pesquisa_data)._a[2]
                );

                if (has_dt) match_data = true;
                else match_data = false;
              } else {
                match_data = false;
              }
            } else match_data = true;
          } else match_data = true;
          return found_value && match_data;
        })
        .map((card) => {
          const found_apt_aberto = card.apontamentos
            ? card.apontamentos.find(
                (apt) => apt.apontamento.status == "Aberto"
              )
            : undefined;
          return found_apt_aberto
            ? {
                apontamento: found_apt_aberto.apontamento,
                ordem_de_producao: found_apt_aberto.ordem_de_producao,
                operador: found_apt_aberto.operador,
                recurso: card,
              }
            : {
                apontamento: {},
                ordem_de_producao: {},
                operador: {},
                recurso: card,
              };
        });
      render_card_template_with_this_context(
        filtered,
        card_template_recursos,
        toggle_card,
        () => {},
        "recurso"
      );
      break;
    }
    case "cards_operador": {
      const filtered = cards_operador
        .filter((operador) => {
          let found_value = false;
          let match_data = false;
          if (pesquisa_input) {
            if (pesquisa_input.length > 0) {
              const values = extractValues(operador);
              const found = values.find((v) =>
                typeof v == "string"
                  ? v.includes(pesquisa_input)
                  : typeof v == "number"
                  ? `${v}`.includes(pesquisa_input)
                  : null
              );
              if (found) found_value = true;
            } else found_value = true;
          } else found_value = true;
          if (pesquisa_data) {
            if (pesquisa_data.length > 0) {
              if (operador.apontamentos) {
                const has_dt = operador.apontamentos.find(
                  (apt) =>
                    moment(apt.apontamento.dt_inicio)._a[0] ==
                      moment(pesquisa_data)._a[0] &&
                    moment(apt.apontamento.dt_inicio)._a[1] ==
                      moment(pesquisa_data)._a[1] &&
                    moment(apt.apontamento.dt_inicio)._a[2] ==
                      moment(pesquisa_data)._a[2]
                );

                if (has_dt) match_data = true;
                else match_data = false;
              } else {
                match_data = false;
              }
            } else match_data = true;
          } else match_data = true;
          return found_value && match_data;
        })
        .map((card) => {
          const found_apt_aberto = card.apontamentos
            ? card.apontamentos.find(
                (apt) => apt.apontamento.status == "Aberto"
              )
            : undefined;
          return found_apt_aberto
            ? {
                apontamento: found_apt_aberto.apontamento,
                ordem_de_producao: found_apt_aberto.ordem_de_producao,
                operador: card,
                recurso: found_apt_aberto.recurso,
              }
            : {
                apontamento: {},
                ordem_de_producao: {},
                operador: card,
                recurso: {},
              };
        });
      render_card_template_with_this_context(
        filtered,
        card_template_operador,
        toggle_card,
        () => {},
        "recurso"
      );
      break;
    }
    default:
      break;
  }
};

const apontamento_app_realtime = async (data) => {
  const { operador, ordem_de_producao, recurso, apontamento } = data;
  const to_map_selects = (card, id) => {
    if (card.name == id) {
      if (apontamento.status == "Aberto") {
        if (card.apontamentos.length == 0) {
          card.apontamentos.push(data);
          return card;
        } else {
          const found = card.apontamentos.findIndex(
            (e) => e.name == apontamento.name
          );
          if (found != -1) {
            card.apontamentos[found] = data;
            return card;
          } else {
            card.apontamentos.push(data);
            return card;
          }
        }
      }
      if (apontamento.status == "Fechado") {
        if (card.apontamentos.length != 0) {
          const found = card.apontamentos.findIndex(
            (e) => e.apontamento.name == apontamento.name
          );
          if (found != -1) {
            const new_apontamentos = card.apontamentos.filter(
              (e, index) => index != found
            );
            card.apontamentos = new_apontamentos;
            return card;
          } else {
            return card;
          }
        }
      }
    } else {
      return card;
    }
  };
  const new_cards_operador = cards_operador.map((card) => {
    return to_map_selects(card, operador.name);
  });
  if (ordem_de_producao) {
    const new_cards_ordem_de_producao = cards_ordem_de_producao.map((card) => {
      return to_map_selects(card, ordem_de_producao.name);
    });
    cards_ordem_de_producao = new_cards_ordem_de_producao;
  }
  const new_cards_recurso = cards_recurso.map((card) => {
    return to_map_selects(card, recurso.name);
  });
  if (apontamento.tipo == "Produção") {
    if (apontamento.status == "Fechado") {
      const new_cards_apontamento_aberto =
        cards_apontamento_aberto.length != 0
          ? cards_apontamento_aberto.filter(
              (card) => card.apontamento.name != apontamento.name
            )
          : [];
      cards_apontamento_aberto = new_cards_apontamento_aberto;
    } else {
      const found =
        cards_apontamento_aberto.length != 0
          ? cards_apontamento_aberto.find(
              (e) => e.apontamento.name == apontamento.name
            )
          : undefined;
      if (found) {
      } else {
        cards_apontamento_aberto.push({
          apontamento,
          ordem_de_producao,
          recurso,
          operador,
        });
      }
    }
  }

  if (apontamento.tipo == "Parada") {
    if (apontamento.status == "Fechado") {
      const new_cards_motivo_de_parada =
        cards_motivo_de_parada.length != 0
          ? cards_motivo_de_parada.filter(
              (card) => card.apontamento.name != apontamento.name
            )
          : [];
      cards_motivo_de_parada = new_cards_motivo_de_parada;
    } else {
      const found =
        cards_motivo_de_parada.length != 0
          ? cards_motivo_de_parada.find(
              (e) => e.apontamento.name == apontamento.name
            )
          : undefined;
      if (found) {
      } else {
        cards_motivo_de_parada.push({
          apontamento,
          ordem_de_producao,
          recurso,
          operador,
        });
      }
    }
  }

  cards_operador = new_cards_operador;
  cards_recurso = new_cards_recurso;

  const cud_card = async (
    data,
    card_id,
    template,
    found,
    condition_create,
    condition_remove
  ) => {
    const { operador, ordem_de_producao, recurso, apontamento } = data;
    if (condition_remove && found) {
      await $(`#${card_id}`).addClass("slide-out-up animate");
      await $(`#${card_id}`).on("animationend", () => {
        $(`#${card_id}`).remove();
      });
      return;
    }
    if (found) {
      await $(`#${card_id}`).replaceWith(
        frappe.render_template(template, {
          apontamento,
          ordem_de_producao,
          operador,
          recurso,
        })
      );
      $(`#${card_id}`).addClass("shake animate");
      $(`#${card_id}`).on("click", toggle_card);
      return;
    }
    if (!found && condition_create) {
      await $("#conteiner-card").append(
        frappe.render_template(template, {
          apontamento,
          ordem_de_producao,
          operador,
          recurso,
        })
      );
      $(`#${card_id}`).addClass("slide-in-down animate");
      $(`#${card_id}`).on("click", toggle_card);
      return;
    }
  };
  switch (view) {
    case "cards_apontamento_aberto":
      {
        const found = $(`#${apontamento.name}`).length != 0;
        const condition_create = apontamento.tipo == "Produção";
        const condition_remove = apontamento.status == "Fechado";
        cud_card(
          data,
          apontamento.name,
          card_template_apontamento_aberto,
          found,
          condition_create,
          condition_remove
        );
      }
      break;
    case "cards_motivo_de_parada": {
      const found = $(`#${apontamento.name}`).length != 0;
      const condition_create = apontamento.tipo == "Parada";
      const condition_remove = apontamento.status == "Fechado";
      cud_card(
        data,
        apontamento.name,
        card_template_motivo_de_parada,
        found,
        condition_create,
        condition_remove
      );
      break;
    }
    case "cards_ordem_de_producao": {
      const found = find_name_in_cards(
        cards_ordem_de_producao,
        ordem_de_producao.name
      );
      const condition_create = !found;
      const condition_remove =
        ordem_de_producao.status_op == "Fechado" ||
        ordem_de_producao.status_op == "Cancelado";

      cud_card(
        data,
        ordem_de_producao.name,
        card_template_ordem_de_producao,
        found,
        condition_create,
        condition_remove
      );
      break;
    }
    case "cards_operador": {
      const found = find_name_in_cards(cards_operador, operador.name);
      const condition_create = !found;
      const condition_remove = operador.status == "Inativo";
      cud_card(
        data,
        operador.name,
        card_template_operador,
        found,
        condition_create,
        condition_remove
      );
      break;
    }
    case "cards_recurso": {
      const found = find_name_in_cards(cards_recurso, recurso.name);
      const condition_create = !found;
      const condition_remove = recurso.status == "Inativo";
      cud_card(
        data,
        recurso.name,
        card_template_operador,
        found,
        condition_create,
        condition_remove
      );
      break;
    }
    default:
      break;
  }
};

const get_initial_data = async () => {
  await frappe
    .call(
      "nxlite.nxlite.web_template.apontamento_app.apontamento_app.get_initial_data_apontamento_app"
    )
    .then(async ({ message }) => {
      cards_operador = message.operador;
      cards_ordem_de_producao = message.ordem_de_producao;
      cards_recurso = message.recurso;
      cards_apontamento_aberto = message.apontamentos_abertos;
      cards_motivo_de_parada = message.motivo_parada;
    });
};
const render_card_template_with_this_context = async (
  data,
  template,
  toggle_card,
  toggle_plus,
  key
) => {
  $("#conteiner-card").empty();
  data.map((d) => {
    if (d) {
      $("#conteiner-card").append(frappe.render_template(template, d));
      $(`#${d[key].name}`).on("click", toggle_card);
      $(`#${d[key].name}-plus`).on("click", toggle_plus);
    }
  });
};

const toggle_plus_apontamento_aberto = async (e) => {
  const apt_id = e.currentTarget.id.replace("-plus", "");
  const found = cards_apontamento_aberto.find(
    (card) => card.apontamento.name == apt_id
  );
  let d = new frappe.ui.Dialog({
    name: "dop",
    title: `Detalhes da OP - ${found.ordem_de_producao.name}`,
    size: "extra-large",
    fields: [
      {
        label: `teste ${found.apontamento.name}`,
        fieldname: "",
        fieldtype: "HTML",
        options: `
          <div class="flex-container"> 
            <div name="1" class="flex-row"> 
                    <div class="line"><strong> Descrição/Item: </strong>${
                      found.ordem_de_producao.descricao
                        ? found.ordem_de_producao.descricao
                        : ""
                    }</div> 

                    <div class="line"><strong> Operador: </strong>${
                      found.operador.nome ? found.operador.nome : ""
                    }</div> 

                    <div class="line"><strong> Recurso: </strong>${
                      found.recurso.nome ? found.recurso.nome : ""
                    }</div>

                    <div class="line"><strong> Data de Entrega: </strong>${
                      found.ordem_de_producao.dt_entrega
                        ? moment(found.ordem_de_producao.dt_entrega).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : ""
                    }</div>

                    <div class="line"><strong> Início: </strong>${
                      found.apontamento.dt_inicio
                        ? moment(found.apontamento.dt_inicio).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : ""
                    }</div>

                    <div class="line"><strong> Quantidade: </strong>${
                      found.ordem_de_producao.quantidade
                        ? found.ordem_de_producao.quantidade
                        : ""
                    }</div> 

                <div class="line"><strong> Quantidade Realizada: </strong>${
                  found.ordem_de_producao.quantidade__realizada
                    ? found.ordem_de_producao.quantidade__realizada
                    : ""
                }</div> 

                
            </div>
            <div name="2" class="flex-row"> 
                      
            <div class="line"><strong> Pedido: </strong>${
              found.ordem_de_producao.pedido
                ? found.ordem_de_producao.pedido
                : ""
            }</div>
                
                <div class="line"><strong> Desenho: </strong>${
                  found.ordem_de_producao.desenho
                    ? found.ordem_de_producao.desenho
                    : ""
                }</div> 

                <div class="line"><strong> Revisão: </strong>${
                  found.ordem_de_producao.revisao
                    ? found.ordem_de_producao.revisao
                    : ""
                }</div>    
            
            <div class="line"><strong> Posição: </strong>${
              found.ordem_de_producao.posicao
                ? found.ordem_de_producao.posicao
                : ""
            }</div> 

                <div class="line"><strong> Estrututa: </strong>${
                  found.ordem_de_producao.item
                    ? found.ordem_de_producao.item
                    : ""
                }</div> 

                <div class="line"><strong> Observação: </strong>${
                  found.ordem_de_producao.observacao
                    ? found.ordem_de_producao.observacao
                    : ""
                }</div>
            </div>
        </div>`,
      },
    ],
    primary_action: async () => {
      d.hide();
    },
    primary_action_label: "OK",
  });
  d.show();
  //   });
};

const toggle_plus_ordem_de_producao = async (e) => {
  //trigger plus info
  // debugger;
  const op_id = e.currentTarget.id.replace("-plus", "");
  const found = cards_ordem_de_producao.find((card) => card.name == op_id);
  const template =
    found.apontamentos.length != 0
      ? `
    <div class="flex-container"> 
      <div name="1" class="flex-row"> 
            <div class="line"><strong>Descrição: </strong>${
              found.apontamentos[0].ordem_de_producao
                ? found.apontamentos[0].ordem_de_producao.descricao
                : found.item
                ? found.item.descricao
                : ""
            }</div> 
        <div class="line"><strong>Operador: </strong>${
          found.apontamentos[0].operador
            ? found.apontamentos[0].operador.name || ""
            : ""
        }</div> 
        <div class="line"><strong>Recurso: </strong>${
          found.apontamentos[0].recurso
            ? found.apontamentos[0].recurso.nome || ""
            : ""
        }</div>
        
        <div class="line"><strong>Data de Entrega: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? moment(found.apontamentos[0].ordem_de_producao.dt_entrega).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : ""
        }</div>
        <div class="line"><strong>Início: </strong>${
          found.apontamentos[0].apontamento
            ? moment(found.apontamentos[0].apontamento.dt_inicio).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : ""
        }</div>
        <div class="line"><strong>Quantidade: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.quantidade || ""
            : ""
        }</div> 
        <div class="line"><strong>Quantidade Realizada: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.quantidade_realizada || ""
            : ""
        }</div> 
        
      </div>
      <div name="2" class="flex-row"> 

        <div class="line"><strong>Pedido: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.pedido || ""
            : ""
        }</div> 

        <div class="line"><strong>Desenho: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.desenho || ""
            : ""
        }</div>

        <div class="line"><strong>Revisão: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.revisao || ""
            : ""
        }</div>

        <div class="line"><strong>Posição: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.posicao || ""
            : ""
        }</div> 

        <div class="line"><strong>Estrututa: </strong>${
          found.apontamentos[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.item || ""
            : ""
        }</div> 

        <div class="line"><strong>Observação: </strong>${
          found.observacao[0].ordem_de_producao
            ? found.apontamentos[0].ordem_de_producao.observacao || ""
            : ""
        }</div>

      </div>
    </div>`
      : `
    <div>
      <div class="flex-container">
        <div name="1" class="flex-row"> 

          <div class="line"><strong>Descrição/Item: </strong>${
            found.descricao
              ? found.descricao
              : found.item
              ? found.item.descricao
              : ""
          }</div>
          
          <div class="line"><strong>Data de Entrega: </strong>${
            found.dt_entrega
              ? moment(found.dt_entrega).format("DD/MM/YYYY HH:mm:ss")
              : ""
          }</div> 
          <div class="line"><strong>Início: </strong>${
            found.dt_inicio
              ? moment(found.dt_inicio).format("DD/MM/YYYY HH:mm:ss")
              : ""
          }</div> 
          <div class="line"><strong>Quantidade: </strong>${
            found.item ? found.item.quantidade || "" : ""
          }</div> 
          <div class="line"><strong>Quantidade Realizada: </strong>${
            found.item ? found.item.quantidade || "" : ""
          }</div>          
          <div class="line"><strong>Desenho: </strong>${
            found.desenho ? found.desenho || "" : ""
          }</div>
        </div>
        <div name="2" class="flex-row"> 
          <div class="line"><strong>Pedido: </strong>${
            found.pedido ? found.pedido || "" : ""
          }</div>
          <div class="line"><strong>Desenho: </strong>${
            found.desenho ? found.desenho || "" : ""
          }</div>
          <div class="line"><strong>Revisão: </strong>${
            found.revisao ? found.revisao || "" : ""
          }</div>
          <div class="line"><strong>Posição: </strong>${
            found.posicao ? found.posicao || "" : ""
          }</div>
          <div class="line"><strong>Estrutura: </strong>${
            found.estrutura ? found.estrutura || "" : ""
          }</div>
          <div class="line"><strong>Observação: </strong>${
            found.observacao ? found.observacao || "" : ""
          }</div>
        </div>
    </div>`;

  let d = new frappe.ui.Dialog({
    fieldname: "dop",
    size: "extra-large",
    title: `Detalhes da OP - ${found.name}`,
    fields: [
      {
        label: `teste ${found.name}`,
        fieldname: "",
        fieldtype: "HTML",
        options: template,
      },
    ], //add aqui os fields,
    primary_action: async () => {
      d.hide();
    },
    primary_action_label: "Fechar",
  });
  d.show();
};

const toggle_tab_on_click = async (str_tag_to_find) => {
  const type_to_render = str_tag_to_find.currentTarget.id.replace(
    "tab_",
    "cards_"
  );
  function incrementDuration(durationString, incrementSeconds) {
    const duration = moment.duration(durationString);
    duration.add(incrementSeconds, "seconds");

    const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  function getDuration(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  $($("#pesquisa_geral")[0]).val("");
  $($("#input_data")[0]).val("");
  $("#conteiner-card").empty();
  $("#options-tab-buttons").empty();
  clearInterval(cur_interval_id);
  switch (type_to_render) {
    case "cards_apontamento_aberto": {
      view = "cards_apontamento_aberto";
      if (cards_apontamento_aberto.length != 0) {
        if (
          selected_operador ||
          selected_ordem_de_producao ||
          selected_recurso
        ) {
          const filtered = cards_apontamento_aberto.filter((c) => {
            let reason_to_keep = true;
            if (selected_operador) {
              if (c.operador) {
                reason_to_keep = c.operador.name == selected_operador;
              }
            }
            if (selected_recurso) {
              if (c.recurso) {
                if (reason_to_keep)
                  reason_to_keep = c.recurso.name == selected_recurso;
              }
            }
            if (selected_ordem_de_producao) {
              if (c.ordem_de_producao) {
                if (reason_to_keep)
                  reason_to_keep =
                    c.ordem_de_producao.name == selected_ordem_de_producao;
              }
            }
            return reason_to_keep;
          });
          render_card_template_with_this_context(
            filtered,
            card_template_apontamento_aberto,
            toggle_card,
            toggle_plus_apontamento_aberto,
            "apontamento"
          );
        } else {
          render_card_template_with_this_context(
            cards_apontamento_aberto,
            card_template_apontamento_aberto,
            toggle_card,
            toggle_plus_apontamento_aberto,
            "apontamento"
          );
        }
        const count_seconds = () => {
          $(".normal_card.parent").each((index) => {
            const apontamento_id = $('[name="apontamento"]')[
              index
            ].innerText.replace("Apontamento: ", "");
            const timer_value = $(`#${apontamento_id}_count`)[0].innerText;
            if (timer_value.length == 0) {
              const apontamento_card = cards_apontamento_aberto.find(
                (c) => c.apontamento.name == apontamento_id
              );
              const dt_inicio = apontamento_card.apontamento.dt_inicio;
              const duration = getDuration(
                dt_inicio,
                frappe.datetime.now_datetime()
              );
              $(`#${apontamento_id}_count`)[0].innerText = duration;
            } else {
              const new_duration = incrementDuration(timer_value, 1);
              $(`#${apontamento_id}_count`)[0].innerText = new_duration;
            }
          });
        };
        count_seconds()
        cur_interval_id = setInterval(count_seconds, 1000);
      }
      break;
    }
    case "cards_motivo_de_parada":
      view = "cards_motivo_de_parada";
      // const template = `<button id="add-motivo-parada" <div class="text-column-subheader"></div><div class="columns-container"><div class="selected text-line"></div><div class="text-line"><div class="text-column centralizador bottom">Adicionar</div></div></div></button>`;
      const template = `<button id="add-motivo-parada" class="btn btn-light my-sm-0">Adicionar</button>`;
      await $("#options-tab-buttons").append(template);
      if (selected_motivo_de_parada) {
        selected_motivo_de_parada = null;
        $("#selected_motivo_de_parada").empty();
      }
      await $("#add-motivo-parada").on("click", async () => {
        const mps = await frappe.call(
          "nxlite.nxlite.web_template.apontamento_app.apontamento_app.get_motivo_de_parada"
        );
        d = new frappe.ui.Dialog({
          title: "Selecione o motivo de parada:",
          fields: [
            {
              label: "Motivo de Parada",
              fieldtype: "HTML",
              fieldname: "motivo_parada",
              options: frappe.render_template(
                card_template_motivo_de_parada_options,
                {
                  motivo_de_parada: await mps.message,
                }
              ),
              reqd: 1,
            },
          ],
        });
        d.show();

        $(document).ready(function () {
          function waitForElement(selector, callback) {
            if ($(selector).length) {
              callback();
            } else {
              setTimeout(function () {
                waitForElement(selector, callback);
              }, 100);
            }
          }
          waitForElement("#motivo_de_parada_options", async function () {
            await mps.message.map((mp) => {
              $(`#${mp.name}`).on("click", async () => {
                d.hide();
                selected_motivo_de_parada = mp.name;
                $("#selected_motivo_de_parada").append(mp.descricao);
                await all_selected_process();
              });
            });
          });
        });
      });
      if (cards_motivo_de_parada.length != 0) {
        if (
          selected_operador ||
          selected_ordem_de_producao ||
          selected_recurso
        ) {
          const filtered = cards_motivo_de_parada.filter((c) => {
            let reason_to_keep = true;
            if (selected_operador) {
              if (c.operador) {
                reason_to_keep = c.operador.name == selected_operador;
              }
            }
            if (selected_recurso) {
              if (c.recurso) {
                if (reason_to_keep)
                  reason_to_keep = c.recurso.name == selected_recurso;
              }
            }
            if (selected_ordem_de_producao) {
              if (c.ordem_de_producao) {
                if (reason_to_keep)
                  reason_to_keep =
                    c.ordem_de_producao.name == selected_ordem_de_producao;
              }
            }
            return reason_to_keep;
          });
          render_card_template_with_this_context(
            filtered.map((e) =>
              !e.ordem_de_producao ? { ...e, ordem_de_producao: null } : e
            ),
            card_template_motivo_de_parada,
            toggle_card,
            () => {},
            "apontamento"
          );
        } else {
          render_card_template_with_this_context(
            cards_motivo_de_parada.map((e) =>
              !e.ordem_de_producao ? { ...e, ordem_de_producao: null } : e
            ),
            card_template_motivo_de_parada,
            toggle_card,
            () => {},
            "apontamento"
          );
        }
        const count_seconds = () => {
          $(".normal_card").each((index) => {
            const apontamento_id = $(".normal_card")[index].id;
            const timer_value = $(`#${apontamento_id}_count`)[0].innerText;
            if (timer_value.length == 0) {
              const apontamento_card = cards_motivo_de_parada.find(
                (c) => c.apontamento.name == apontamento_id
              );
              const dt_inicio = apontamento_card.apontamento.dt_inicio;
              const duration = getDuration(
                dt_inicio,
                frappe.datetime.now_datetime()
              );
              $(`#${apontamento_id}_count`)[0].innerText = duration;
            } else {
              const new_duration = incrementDuration(timer_value, 1);
              $(`#${apontamento_id}_count`)[0].innerText = new_duration;
            }
          });
        };
        count_seconds()
        cur_interval_id = setInterval(count_seconds, 1000);
      }

      break;

    case "cards_ordem_de_producao":
      view = "cards_ordem_de_producao";
      if (selected_ordem_de_producao) {
        selected_ordem_de_producao = null;
        $(`#${type_to_render.replace("cards_", "selected_")}`).empty();
      }
      if (cards_ordem_de_producao.length != 0) {
        if (
          selected_operador ||
          selected_ordem_de_producao ||
          selected_recurso
        ) {
          const filtered = cards_ordem_de_producao
            .map((card) => {
              const found_apt_aberto = card.apontamentos
                ? card.apontamentos.find(
                    (apt) => apt.apontamento.status == "Aberto"
                  )
                : undefined;
              return found_apt_aberto
                ? {
                    apontamento: found_apt_aberto.apontamento,
                    ordem_de_producao: card,
                    operador: found_apt_aberto.operador,
                    recurso: found_apt_aberto.recurso,
                  }
                : {
                    apontamento: {},
                    ordem_de_producao: card,
                    operador: {},
                    recurso: {},
                  };
            })
            .filter((c) => {
              let reason_to_keep = true;
              if (selected_operador) {
                if (c.operador) {
                  reason_to_keep = c.operador.name == selected_operador;
                }
              }
              if (selected_recurso) {
                if (c.recurso) {
                  if (reason_to_keep)
                    reason_to_keep = c.recurso.name == selected_recurso;
                }
              }
              if (selected_ordem_de_producao) {
                if (c.ordem_de_producao) {
                  if (reason_to_keep)
                    reason_to_keep =
                      c.ordem_de_producao.name == selected_ordem_de_producao;
                }
              }
              return (
                !reason_to_keep && c.ordem_de_producao.estagio == "Em produção"
              );
            });
          render_card_template_with_this_context(
            filtered,
            card_template_ordem_de_producao,
            toggle_card,
            toggle_plus_ordem_de_producao,
            "ordem_de_producao"
          );
        } else {
          const formated = cards_ordem_de_producao.map((card) => {
            if (card.estagio == "Em produção") {
              const found_apt_aberto = card.apontamentos
                ? card.apontamentos.find(
                    (apt) => apt.apontamento.status == "Aberto"
                  )
                : undefined;
              return found_apt_aberto
                ? {
                    apontamento: found_apt_aberto.apontamento,
                    ordem_de_producao: card,
                    operador: found_apt_aberto.operador,
                    recurso: found_apt_aberto.recurso,
                  }
                : {
                    apontamento: {},
                    ordem_de_producao: card,
                    operador: {},
                    recurso: {},
                  };
            }
          });
          render_card_template_with_this_context(
            formated,
            card_template_ordem_de_producao,
            toggle_card,
            toggle_plus_ordem_de_producao,
            "ordem_de_producao"
          );
        }
        const count_seconds = () => {
          $(".normal_card").each((index) => {
            const op_id = $(".normal_card")[index].id;
            const timer_value = $(`#${op_id}_count`)[0].innerText;
            if (timer_value.length == 0) {
              const op_card = cards_ordem_de_producao.find(
                (c) => c.name == op_id
              );
              const dt_entrega = op_card.dt_entrega;
              const duration = getDuration(
                dt_entrega,
                frappe.datetime.now_datetime()
              );
              $(`#${op_id}_count`)[0].innerText = duration.includes('-') ? '-' + duration.replaceAll('-', '') : duration;
            } else {
              if(timer_value.includes('-')) {
              const new_duration = incrementDuration(timer_value.replaceAll('-', ''), 1);
              $(`#${op_id}_count`)[0].innerText = '-' + new_duration;
              } else {
                const new_duration = incrementDuration(timer_value, 1);
                $(`#${op_id}_count`)[0].innerText = new_duration;
              }
            }
          });
        };
        count_seconds()
        cur_interval_id = setInterval(count_seconds, 1000);
      }
      break;

    case "cards_recurso":
      view = "cards_recurso";
      if (selected_recurso) {
        selected_recurso = null;
        $(`#${type_to_render.replace("cards_", "selected_")}`).empty();
      }
      if (cards_recurso.length != 0) {
        // if (
        //   selected_operador ||
        //   selected_ordem_de_producao ||
        //   selected_recurso
        // ) {

        //   const filtered = cards_recurso
        //     .map((card) => {
        //       if (card) {
        //         const found_apt_aberto = card.apontamentos
        //           ? card.apontamentos.find(
        //               (apt) => apt.apontamento.status == "Aberto"
        //             )
        //           : undefined;
        //         return found_apt_aberto
        //           ? {
        //               apontamento: found_apt_aberto.apontamento,
        //               ordem_de_producao: found_apt_aberto.ordem_de_producao
        //                 ? found_apt_aberto.ordem_de_producao
        //                 : null,
        //               operador: found_apt_aberto.operador,
        //               recurso: found_apt_aberto.recurso,
        //             }
        //           : {
        //               apontamento: {},
        //               ordem_de_producao: {},
        //               operador: {},
        //               recurso: card,
        //             };
        //       }
        //     })
        //     .filter((c) => {
        //
        //       let reason_to_keep = true;
        //       if (selected_operador) {
        //         if (c.operador) {
        //           reason_to_keep = c.operador.name == selected_operador;
        //         }
        //       }
        //       if (selected_recurso) {
        //         if (c.recurso) {
        //           reason_to_keep = c.recurso.name == selected_recurso;
        //         }
        //       }
        //       if (selected_ordem_de_producao) {
        //         if (c.ordem_de_producao) {
        //           reason_to_keep =
        //             c.ordem_de_producao.name == selected_ordem_de_producao;
        //         }
        //       }
        //       return !reason_to_keep;
        //     });
        //   render_card_template_with_this_context(
        //     filtered,
        //     card_template_recursos,
        //     toggle_card,
        //     null,
        //     "recurso"
        //   );
        // } else {
        const formated = cards_recurso.map((card) => {
          const found_apt_aberto = card.apontamentos
            ? card.apontamentos.find(
                (apt) => apt.apontamento.status == "Aberto"
              )
            : undefined;
          return found_apt_aberto
            ? {
                apontamento: found_apt_aberto.apontamento,
                ordem_de_producao: found_apt_aberto.ordem_de_producao
                  ? found_apt_aberto.ordem_de_producao
                  : null,
                operador: found_apt_aberto.operador,
                recurso: found_apt_aberto.recurso,
              }
            : {
                apontamento: {},
                ordem_de_producao: {},
                operador: {},
                recurso: card,
              };
        });
        render_card_template_with_this_context(
          formated,
          card_template_recursos,
          toggle_card,
          null,
          "recurso"
        );
      }
      // }
      break;

    case "cards_operador":
      view = "cards_operador";
      if (selected_operador) {
        selected_operador = null;
        $(`#${type_to_render.replace("cards_", "selected_")}`).empty();
      }
      if (cards_operador.length != 0) {
        // if (
        //   selected_operador ||
        //   selected_ordem_de_producao ||
        //   selected_recurso
        // ) {

        //   const filtered = cards_operador

        //     .map((card) => {
        //       if (card) {
        //         const found_apt_aberto = card.apontamentos
        //           ? card.apontamentos.find(
        //               (apt) => apt.apontamento.status == "Aberto"
        //             )
        //           : undefined;
        //         return found_apt_aberto
        //           ? {
        //               apontamento: found_apt_aberto.apontamento,
        //               ordem_de_producao: found_apt_aberto.ordem_de_producao
        //                 ? found_apt_aberto.ordem_de_producao
        //                 : null,
        //               operador: found_apt_aberto.operador,
        //               recurso: found_apt_aberto.recurso,
        //             }
        //           : {
        //               apontamento: {},
        //               ordem_de_producao: {},
        //               operador: card,
        //               recurso: {},
        //             };
        //       }
        //     })
        //     .filter((c) => {
        //
        //       let reason_to_keep = true;
        //       if (selected_operador) {
        //         if (c.operador) {
        //           reason_to_keep = c.operador.name == selected_operador;
        //         }
        //       }
        //       if (selected_recurso) {
        //         if (c.recurso) {
        //           reason_to_keep = c.recurso.name == selected_recurso;
        //         }
        //       }
        //       if (selected_ordem_de_producao) {
        //         if (c.ordem_de_producao) {
        //           reason_to_keep =
        //             c.ordem_de_producao.name == selected_ordem_de_producao;
        //         }
        //       }
        //       return !reason_to_keep;
        //     });
        //   render_card_template_with_this_context(
        //     filtered,
        //     card_template_operador,
        //     toggle_card,
        //     null,
        //     "operador"
        //   );
        // } else {
        const formated = cards_operador.map((card) => {
          const found_apt_aberto = card.apontamentos
            ? card.apontamentos.find(
                (apt) => apt.apontamento.status == "Aberto"
              )
            : undefined;
          return found_apt_aberto
            ? {
                apontamento: found_apt_aberto.apontamento,
                ordem_de_producao: found_apt_aberto.ordem_de_producao
                  ? found_apt_aberto.ordem_de_producao
                  : null,
                operador: found_apt_aberto.operador,
                recurso: found_apt_aberto.recurso,
              }
            : {
                apontamento: {},
                ordem_de_producao: {},
                operador: card,
                recurso: {},
              };
        });
        render_card_template_with_this_context(
          formated,
          card_template_operador,
          toggle_card,
          null,
          "operador"
        );
      }
      // }
      break;

    default:
      break;
  }
};

const find_apt_by_tp = (apts, tp) => apts.find((apt) => apt.tipo == tp);
const find_name_in_cards = (cards, name) => cards.find((c) => c.name == name);
const all_selected_process = async () => {
  if (selected_motivo_de_parada && selected_recurso && selected_operador) {
    await frappe
      .call(
        "nxlite.nxlite.web_template.apontamento_app.apontamento_app.open_apontamento",
        {
          ordem_de_producao: selected_ordem_de_producao
            ? selected_ordem_de_producao
            : undefined,
          motivo_parada: selected_motivo_de_parada,
          recurso: selected_recurso,
          operador: selected_operador,
          tipo: "Parada",
        }
      )
      .then(({ message }) => {
        if (message.status == "Aberto") {
          frappe.msgprint({
            message: "Parada iniciada.",
            indicator: 'green',
            title: "Parada",
          });

          // function animate_and_clean_it(to_empty) {
          //   //TODO ANIMAÇãO // animação da imagem ??
          //   $($(`#${to_empty}`)[0]).empty();
          // }
          // if (selected_operador) animate_and_clean_it("selected_operador");
          // if (selected_recurso) animate_and_clean_it("selected_recurso");
          // if (selected_ordem_de_producao)
          //   animate_and_clean_it("selected_ordem_de_producao");
          // if (selected_motivo_de_parada)
          //   animate_and_clean_it("selected_motivo_de_parada");
          // selected_operador = null;
          // selected_recurso = null;
          // selected_ordem_de_producao = null;
          // selected_motivo_de_parada = null;
        } else {
          frappe.msgprint({
            title: "Apontamento",
            message:
              "Há um apontamento aberto com essas seleções, finalize-o para que possa abrir um novo.",
            indicator: "red",
          });
        }
      });
  }
  if (selected_operador && selected_ordem_de_producao && selected_recurso) {
    //TODO Abre apontamento
    const found = find_name_in_cards(cards_apontamento_aberto);
    await frappe
      .call(
        "nxlite.nxlite.web_template.apontamento_app.apontamento_app.open_apontamento",
        {
          ordem_de_producao: selected_ordem_de_producao,
          recurso: selected_recurso,
          operador: selected_operador,
          tipo: "Produção",
        }
      )
      .then(({ message }) => {
        if (message.status == "Aberto") {
          frappe.msgprint({
            title: "Produção",
            message: "Produção iniciada.",
            indicator: "green",
          });
          // $($("#selected_ordem_de_producao")[0]).empty();
          // $($("#selected_operador")[0]).empty();
          // $($("#selected_recurso")[0]).empty();
          // selected_operador = null;
          // selected_recurso = null;
          // selected_ordem_de_producao = null;
        } else {
          frappe.msgprint({
            title: "Produção",
            message:
              "Há um apontamento aberto com essas seleções, finalize-o para que possa abrir um novo.",
            indicator: "red",
          });
        }
      });
  }
};

const toggle_card = async (card) => {
  const id = card.currentTarget.id;
  console.log('card', card)

  switch (view) {
    case "cards_apontamento_aberto": {
      let d = new frappe.ui.Dialog({
        title: "O que gostaria de fazer com o apontamento?",
        size: "large",
        fieldname: "titulo",
        fields: [
          {
            fieldtype: "Section Break",
            fieldname: "div_btns_apontamentos",
          },
          {
            fieldtype: "Button",
            label: "Finalizar OP",
            fieldname: "btn_finalizar",
            click: async (e) => {
              const op_name = $($($(card)[0].currentTarget)[0]).find(
                '[name="ordem_de_producao"]'
              )[0].id;
              await frappe
                .call(
                  "nxlite.nxlite.web_template.apontamento_app.apontamento_app.verify_op_apts_and_ask_for_finish",
                  {
                    ordem_de_producao: op_name,
                  }
                )
                .then(async ({ message }) => {
                  d.hide();

                  if (message == 1) {
                    let d = new frappe.ui.Dialog({
                      fields: [
                        {
                          fieldtype: "HTML",
                          options:
                            `<div>Está ação vai alterar o estágio da ${op_name} para "Finalizado", todos os apontamento abertos para está OP também serão fechados. Deseja continuar?</div>`,
                        },
                      ],
                      primary_action_label: "Confirme a ação",
                      primary_action: async () => {
                        await frappe
                          .call(
                            "nxlite.nxlite.web_template.apontamento_app.apontamento_app.finish_ordem_de_producao",
                            {
                              ordem_de_producao: op_name,
                              close_all_apts: true,
                            }
                          )
                          .then(({ message }) => {
                            d.hide();
                            //TODO trata o retorno aqui oh
                          });
                      },
                    });
                    d.show();
                  } else {
                    await frappe
                      .call(
                        "nxlite.nxlite.web_template.apontamento_app.apontamento_app.finish_ordem_de_producao",
                        {
                          ordem_de_producao: op_name,
                        }
                      )
                      .then(({ message }) => {
                        //TODO trata o retorno aqui oh
                      });
                  }
                });
              setTimeout(() => {
                $("span.tooltip-content").hide();
              }, 150);
            },
          },
          {
            fieldtype: "Button",
            label: "Fechar",
            fieldname: "btn_fechar",
            click: async () => {
              d.hide();
              d = new frappe.ui.Dialog({
                title: `Deseja fechar o apontamento atual?`,
                fields: [
                  {
                    label: "Peças boas:",
                    fieldtype: "Int",
                    fieldname: "qtde_pecas",
                    default: "0",
                  },
                  {
                    label: "Peças refugadas:",
                    fieldtype: "Int",
                    fieldname: "qtde_pecas_refugadas",
                    default: "0",
                  },
                ],
                size: "large",
                primary_action_label: "Fechar",
                primary_action: async (value) => {
                  await frappe
                    .call(
                      "nxlite.nxlite.web_template.apontamento_app.apontamento_app.close_apontamento",
                      {
                        apontamento_name: id,
                        ...value,
                      }
                    )
                    .then(({ message }) => {
                      d.hide();
                      if (message.status == "Fechado") {
                        frappe.msgprint({
                          title: "Produção",
                          indicator: "green",
                          message: "Apontamento fechado.",
                        });
                      } else {
                        frappe.msgprint({
                          title: "Produção",
                          indicator: "red",
                          message: "Não foi possível fechar o seu apontamento.",
                        });
                      }
                    });
                },
              });
              d.show();
              setTimeout(() => {
                $("span.tooltip-content").hide();
              }, 150);
            },
          },
          {
            fieldtype: "Button",
            label: "Abrir MP",
            fieldname: "btn_iniciar_parada",
            click: async () => {
              d.hide();
              d = new frappe.ui.Dialog({
                title: `Selecione o motivo de parada:`,
                fields: [
                  {
                    label: "Motivo de Parada",
                    fieldtype: "HTML",
                    fieldname: "motivo_parada",
                    options: '<div id="motivo_de_parada_options" ></div>',
                  },
                ],
                size: "large",
              });
              d.show();
              $(document).ready(function () {
                function waitForElement(selector, callback) {
                  if ($(selector).length) {
                    callback();
                  } else {
                    setTimeout(function () {
                      waitForElement(selector, callback);
                    }, 100);
                  }
                }
                waitForElement("#motivo_de_parada_options", async () => {
                  await frappe
                    .call(
                      "nxlite.nxlite.web_template.apontamento_app.apontamento_app.get_motivo_de_parada"
                    )
                    .then((r) => {
                      r.message.map((mp) => {
                        const template = `<button id="${mp.name}">${mp.descricao}</button>`;
                        const func_btn_toggle = async (e) => {
                          await frappe
                            .call(
                              "nxlite.nxlite.web_template.apontamento_app.apontamento_app.close_cur_apt_and_create_parada",
                              {
                                apontamento_name: id,
                                motivo_parada: e.currentTarget.id,
                              }
                            )
                            .then(({ message }) => {
                              d.hide();
                              if (
                                message.status == "Aberto" &&
                                message.tipo == "Parada"
                              ) {
                                frappe.msgprint({
                                  title: "Parada",
                                  indicator: "green",
                                  message: "Parada iniciada.",
                                });
                              } else {
                                frappe.msgprint({
                                  title: "Parada",
                                  indicator: "red",
                                  message:
                                    "Não foi possível finalizar o seu apontamento.",
                                });
                              }
                            });
                        };
                        $("#motivo_de_parada_options").append(
                          frappe.render_template(template, {})
                        );
                        $(`#${mp.name}`).on("click", (e) => {
                          func_btn_toggle(e);
                        });
                      });
                    });
                });
              });
            },
          },
        ],
      });
      d.show();
      break;
    }
    case "cards_motivo_de_parada": {
      const card_id = card.currentTarget.id;
      const found = cards_motivo_de_parada.find(
        (e) => e.apontamento.name == card_id
      );
      const has_op = found.ordem_de_producao;
      const d_fields = has_op
        ? [
            {
              fieldtype: "Section Break",
              fieldname: "div_btns",
            },
            {
              fieldtype: "Button",
              label: "Finalizar MP",
              fieldname: "btn_fin",
              click: async () => {
                await frappe
                  .call(
                    "nxlite.nxlite.web_template.apontamento_app.apontamento_app.close_apontamento",
                    {
                      apontamento_name: id,
                    }
                  )
                  .then(({ message }) => {
                    d.hide();
                    if (message.status == "Fechado") {
                      frappe.msgprint({
                        title: "Parada",
                        indicator: "green",
                        message: "Parada finalizada.",
                      });
                    } else {
                      frappe.msgprint({
                        title: "Parada",
                        indicator: "red",
                        message:
                          "Não foi possível finalizar o seu apontamento.",
                      });
                    }
                  });
              },
            },
            { fieldtype: "Column Break" },
            {
              fieldtype: "Button",
              label: "Iniciar apontamento",
              fieldname: "btn_ini",
              click: async () => {
                const op_id = found.ordem_de_producao.name;
                if (op_id) {
                  await frappe
                    .call(
                      "nxlite.nxlite.web_template.apontamento_app.apontamento_app.close_cur_apt_and_create_producao",
                      {
                        apontamento_name: id,
                        ordem_de_producao: op_id,
                      }
                    )
                    .then(({ message }) => {
                      d.hide();

                      if (
                        message.status == "Aberto" &&
                        message.tipo == "Produção"
                      ) {
                        frappe.msgprint({
                          title: "Produção",
                          indicator: "green",
                          message: "Produção iniciada.",
                        });
                      } else {
                        frappe.msgprint({
                          title: "Produção",
                          indicator: "red",
                          message:
                            "Não foi possível finalizar o seu apontamento.",
                        });
                      }
                    });
                } else {
                  frappe.msgprint({
                    title: "Produção",
                    indicator: "red",
                    message:
                      "Selecione uma ordem de produção para iniciar a produção.",
                  });
                }
              },
            },
          ]
        : [
            {
              fieldtype: "Section Break",
              fieldname: "div_btns",
            },
            {
              fieldtype: "Button",
              label: "Finalizar MP",
              fieldname: "btn_fin",
              click: async () => {
                await frappe
                  .call(
                    "nxlite.nxlite.web_template.apontamento_app.apontamento_app.close_apontamento",
                    {
                      apontamento_name: id,
                    }
                  )
                  .then(({ message }) => {
                    d.hide();
                    if (message.status == "Fechado") {
                      frappe.msgprint({
                        title: "Parada",
                        indicator: "green",
                        message: "Parada finalizada.",
                      });
                    } else {
                      frappe.msgprint({
                        title: "Parada",
                        indicator: "red",
                        message:
                          "Não foi possível finalizar o seu apontamento.",
                      });
                    }
                  });
              },
            },
          ];
      let d = new frappe.ui.Dialog({
        title: "O que você deseja fazer?",
        size: "large",
        fields: d_fields,
      });
      d.show();
      setTimeout(() => {
        $("span.tooltip-content").hide();
      }, 150);
      break;
    }
    case "cards_ordem_de_producao": {
      selected_ordem_de_producao = id;
      const data = find_name_in_cards(cards_ordem_de_producao, id);
      $($("#selected_ordem_de_producao")[0]).empty();
      $($("#selected_ordem_de_producao")[0]).append(
        data.croqui_image
          ? `<img src="${data.croqui_image}" alt="tests"/>`
          : `<img src="/assets/nxlite/images/img-op.png" alt="Logo NXLite OP" class="round-image">`
      ); //data.item.descricao ? data.item.descricao : data.name

      await all_selected_process();
      break;
    }
    case "cards_recurso": {
      selected_recurso = id;
      const data = find_name_in_cards(cards_recurso, id);
      $($("#selected_recurso")[0]).empty();
      $($("#selected_recurso")[0]).append(
        data.imagem
          ? `<img src="${data.imagem}"></img>`
          : `<img src="/assets/nxlite/images/img-maquina.jpg" alt="Logo NXLite Recurso" class="round-image">` //data.nome //aqui
      );
      await all_selected_process();
      break;
    }
    case "cards_operador": {
      selected_operador = id;
      const data = find_name_in_cards(cards_operador, id);
      $($("#selected_operador")[0]).empty();
      $($("#selected_operador")[0]).append(
        data.foto
          ? `<img src="${data.foto}"></img>`
          : `<img src="/assets/nxlite/images/img-operador.png" alt="Logo NXLite Operador" class="round-image">` //data.nome
      );
      await all_selected_process();
      break;
    }
    default:
      break;
  }
};

var card_template_apontamento_aberto = `
{% if ordem_de_producao.criticidade == "Urgente" %}
  <div class="urgente_card parent" style="text-decoration: none; color: inherit;">
{% else %}
  <div class="normal_card parent" style="text-decoration: none; color: inherit;">
{% endif %}
    <div class="card with-circle">
      <a id="{{apontamento.name}}-plus" class="card-header-with-circle" style="cursor: pointer;">
        <div class="circle-green">
          <span class="circle-text"></span>
        </div>
        <div class="header-content">
          <p class="card-title">{{ordem_de_producao.name}} - {{operador.nome}}</p>
        </div>
        <div class="header-btn-plus">
          <input class="btn-plus" type="button" value="+"/>
        </div>
      </a>
      <div class="card-body" id="{{apontamento.name}}">
        <div class="image-container">
          {% if operador.foto %}
            <img src="{{operador.foto}}" alt="Imagem Redonda" class="round-image">
          {% else %}
            <img src="/assets/nxlite/images/img-operador.png" alt="Logo NXLite Operador" class="round-image">
          {% endif %}
          
          {% if recurso.imagem %}
            <img src="{{recurso.imagem}}" alt="Imagem Redonda" class="round-image">
          {% else %}
            <img src="/assets/nxlite/images/img-maquina.jpg" alt="Logo NXLite Recurso" class="round-image">
          {% endif %}
        </div>
        <div class="info-container">
          <div name="apontamento" class="info-item" apontamento="{{apontamento.name}}">
            <strong>Apontamento: </strong>{{apontamento.name}}
          </div>
          <div name="recurso" class="info-item">
            <strong>Recurso: </strong>{{recurso.nome}}
          </div>
          <div name="operador" class="info-item">
            <strong>Operador: </strong>{{operador.nome}}
          </div>
          <div name="ordem_de_producao" class="info-item" id="{{ordem_de_producao.name}}">
            <strong>OP: </strong>{{ordem_de_producao.name}}
          </div> 
          <div name="quantidade" class="info-item">
            <strong>Quantidade: </strong>{{ordem_de_producao.quantidade}}
          </div>
          <div name="dt_entrega" class="info-item">
            <strong>Data de Entrega:</strong> {{frappe.format(ordem_de_producao.dt_entrega, {"fieldtype": "Datetime"})}}
          </div>

          <div class="info-item flex-time"> 
              <strong> Tempo: </strong> <div class="time" id="{{apontamento.name}}_count" name="timer_count" > </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
`;

var card_template_motivo_de_parada = `
<a class="normal_card" style="text-decoration: none; color: inherit;" id="{{apontamento.name}}">
            <div class="card with-circle">
                <div class="card-header-with-circle">
                    <div class="circle-yellow">
                        <span class="circle-text"></span>
                    </div>
                    <div class="header-content">
                        <p class="card-title">{{apontamento.name}} - {{recurso.nome}}</p>
                    </div>
                </div>
                <div class="card-body">
                    <div class="image-container">
                    {% if recurso.imagem %}
                    		<img src="{{recurso.imagem}}" alt="Imagem Redonda" class="round-image">
                		{% else %}
                    		<img src="/assets/nxlite/images/img-maquina.jpg" alt="Logo NXLite Recurso" class="round-image">
                		{% endif %}
                    </div>
                  <div class="info-container">
                        <div name="operador" class="info-item"><strong>Operador: </strong>{{operador.nome}}</div>
                        {% if apontamento.tipo == "Parada" %}
                         <div name="motivo_parada" class="info-item"><strong>Motivo Parada: </strong> {{apontamento.desc_motivo_parada}}</div>
                         <div name="dt_inicio_ap" class="info-item"><strong>Data Início (AP): </strong> {{ frappe.format(apontamento.dt_inicio, {"fieldtype": "Datetime"}) }}</div>    
                        {% else %}
                            <div name="op" class="info-item"><strong>OP: </strong>{{ordem_de_producao.name}}</div>
                        {% endif %} 
                            <div class="info-item flex-time"> <strong>Tempo: </strong>
                            <div id="{{apontamento.name}}_count" name="timer_count" >
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </a>
`;

var card_template_ordem_de_producao = `
{% if ordem_de_producao.criticidade == "Urgente"  %}
  <div class="urgente_card parent" style="text-decoration: none; color: inherit;" >
{% else %}
  <div class="normal_card parent" style="text-decoration: none; color: inherit;" id="{{ordem_de_producao.name}}">
{% endif %}
          <div class="card with-circle">
              <a id="{{ordem_de_producao.name}}-plus" class="card-header-with-circle" style="cursor: pointer;">

                {% if apontamento.name %}
                          {% if apontamento.status == "Aberto" && apontamento.tipo == "Produção"%}
                              <div class="circle-green">
                                  <span class="circle-text"></span>
                              </div>
                          {% else %}
                              <div class="circle-yellow">
                                  <span class="circle-text"></span>
                              </div>
                          {% endif %}
                {% else %}
                        <div class="circle-red">
                                  <span class="circle-text"></span>
                            </div>
                {% endif %}


                {% if apontamento.name %}
                    <div class="header-content">
                        <p class="card-title">{{ordem_de_producao.name}} - {{recurso.nome}}</p>
                    </div>
                {% else %}
                    <div class="header-content">
                        <p class="card-title">{{ordem_de_producao.name}}</p>
                    </div>
                {% endif %}
                  <div class="header-btn-plus" >
                      <input class="btn-plus" type="button" value="+"/>
                  </div>
              </a>
              {% if apontamento.name %}
              <div class="card-body" id="{{ordem_de_producao.name}}">
            <div class="image-container">
              {% if ordem_de_producao.croqui_image %}
                <img src="{{ordem_de_producao.croqui_image}}" alt="Imagem Redonda" class="round-image">
              {% endif %}
              {% if operador.foto && recurso.imagem %}
                <img src="{{operador.foto}}" alt="Imagem Redonda" class="round-image">
              {% else %}
                  <img src="/assets/nxlite/images/img-operador.png" alt="Logo NXLite Operador" class="round-image">
              {% endif %}
            </div>
            <div class="info-container">
                <div name="operador" class="info-item"><strong>Operador: </strong>{{operador.nome}}</div>
                <div name="op" class="info-item"><strong>OP: </strong>{{ordem_de_producao.name}}</div>    
                <div name="motivo_parada" class="info-item"><strong>Motivo Parada: </strong> {{apontamento.desc_motivo_parada}}</div>            
                <div name="dt_inicio_ap" class="info-item"><strong>Data Início (AP): </strong> {{ frappe.format(apontamento.dt_inicio, {"fieldtype": "Datetime"}) }}</div>    
                <div class="info-item flex-time"> <strong>Tempo de entrega: </strong>
                  <div id="{{ordem_de_producao.name}}_count" name="timer_count" >
                  </div>
                </div>
            </div>
        </div>
        {% else %}
        <div class="card-body" id="{{ordem_de_producao.name}}">
            <div class="image-container">
            {% if ordem_de_producao.croqui_image %}
                <img src="{{ordem_de_producao.croqui_image}}" alt="Imagem Redonda" class="round-image">
            {% else %}
                <img src="/assets/nxlite/images/img-op.png" alt="Logo NXLite OP" class="round-image">
            {% endif %}
            </div>
            <div class="info-container">
                <div name="descricao" class="info-item"><strong>Descrição: </strong>{{ordem_de_producao.descricao}}</div>
                <div name="entidade" class="info-item"><strong>Cliente: </strong>{{ordem_de_producao.entidade}}</div>    
                <div name="motivo_parada" class="info-item"><strong>Pedido: </strong> {{ordem_de_producao.pedido}}</div>            
                <div name="dt_inicio_ap" class="info-item"><strong>Data Início (OP): </strong> {{ frappe.format(ordem_de_producao.dt_inicio, {"fieldtype": "Datetime"}) }}</div>    
                <div class="info-item flex-time"> <strong>Tempo de entrega: </strong>
                  <div id="{{ordem_de_producao.name}}_count" name="timer_count" > </div>
                </div>
                </div>
        </div>
        {% endif %}
    </div>
</div>
`;

var card_template_recursos = `
<a class="normal_card" style="text-decoration: none; color: inherit;" id="{{recurso.name}}">
    <div class="card with-circle">
        <div class="card-header-with-circle">
            {% if apontamento.name %}
                {% if apontamento.status == "Aberto" && apontamento.tipo == "Produção"%}
                    <div class="circle-green">
                        <span class="circle-text"></span>
                    </div>
                {% else %}
                    <div class="circle-yellow">
                        <span class="circle-text"></span>
                    </div>
                {% endif %}
            {% else %}
                <div class="circle-red">
                    <span class="circle-text"></span>
                </div>
            {% endif %}

            {% if apontamento.name %}
                <div class="header-content">
                    {% if ordem_de_producao %}
                        <p class="card-title">{{ordem_de_producao.name}} - {{recurso.nome}}</p>
                    {% else %}
                        <p class="card-title">{{recurso.nome}}</p>
                    {% endif %}
                </div>
            {% else %}
                <div class="header-content">
                    <p class="card-title">{{recurso.nome}}</p>
                </div>
            {% endif %}

        </div>

        <div class="card-body">
            <div class="image-container">
                {% if recurso.imagem %}
                    <img src="{{recurso.imagem}}" alt="Imagem Redonda" class="round-image">
                {% else %}
                    <img src="/assets/nxlite/images/img-maquina.jpg" alt="Logo NXLite Recurso" class="round-image">
                {% endif %}
            </div>

            {% if ordem_de_producao %}
                <div class="info-container">
                    <div name="recurso" class="info-item">
                        <strong>Operador: </strong>{{operador.nome}}
                    </div>
                    <div name="op" class="info-item">
                        <strong>OP: </strong>{{ordem_de_producao.name}}
                    </div> 
                    <div name="quantidade" class="info-item">
                        <strong>Quantidade: </strong>{{ordem_de_producao.quantidade}}
                    </div>
                    <div name="dt_inicio_ap" class="info-item">
                        <strong>Data Início (AP): </strong>{{ frappe.format(apontamento.dt_inicio, {"fieldtype": "Datetime"}) }}
                    </div>  
                </div>
            {% else %}
                <div class="info-container">
                    <div name="recurso" class="info-item">
                        <strong>Recurso: </strong>{{recurso.nome}}
                    </div>
                    <div name="centro_de_custo" class="info-item">
                        <strong>Centro de Custo: </strong>{{recurso.centro_de_custo}}
                    </div> 
                    <div name="empresa" class="info-item">
                        <strong>Empresa: </strong>{{recurso.empresa}}
                    </div>
                </div>
            {% endif %}
        </div>
    </div>
</a>
`;

var card_template_operador = `
<a class="normal_card" style="text-decoration: none; color: inherit;" id="{{operador.name}}">
    <div class="card with-circle">
        <div class="card-header-with-circle">
            {% if apontamento.name %}
                {% if apontamento.status == "Aberto" && apontamento.tipo == "Produção"%}
                    <div class="circle-green">
                        <span class="circle-text"></span>
                    </div> 
                {% else %}
                    <div class="circle-yellow">
                        <span class="circle-text"></span>
                    </div>
                {% endif %}               
            {% else %}
                <div class="circle-red">
                    <span class="circle-text"></span>
                </div>
            {% endif %}
            <div class="header-content">
                {% if not ordem_de_producao %}
                    <p class="card-title">{{operador.nome}}</p>
                {% else %}
                    <p class="card-title">{{ordem_de_producao.name}} - {{operador.nome}}</p>
                {% endif %}
            </div>
        </div>
        <div class="card-body">
            <div class="image-container-xl">
                {% if operador.foto %}
                    <img src="{{operador.foto}}" alt="Imagem Redonda" class="round-image-xl">
                {% else %}
                    <img src="/assets/nxlite/images/img-operador.png" alt="Logo NXLite Operador" alt="Logo NXLite Recurso" class="round-image-xl">
                {% endif %}
            </div>
            <div class="info-container">
                <div name="operador" class="info-item">
                    <strong>Operador: </strong>{{operador.nome}}
                </div>
                <div name="identificacao" class="info-item">
                    <strong>ID: </strong>{{operador.identificacao}}
                </div>
            </div>
        </div>
    </div>
</a>
`;

var card_template_motivo_de_parada_options = `
  <div id="motivo_de_parada_options">
    {% for mp in motivo_de_parada %}
      <button id="{{mp.name}}">{{mp.descricao}}</button>
    {% endfor %}
  </div>
`;
